// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/* Drupal Node Links */
ul.links {
  margin: 0;
  padding: 0;
  li {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0;
  }
  &.inline {
    display: block; // why?
  }
}

/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */
.breadcrumb {
  margin-top: 1em;
  margin-bottom: 1em;
  ol {
    display: inline;
    margin: 0;
    padding: 0;
    li{
      list-style: none;
      display: inline;
    }
  }
  .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px;
    &:after {
      content: ":";
    }
  }
}
.breadcrumb-seperator{
  color:$grey_dark;
  font-size:0.9em;
  padding:0 5px;
}

/* =============================================================================
 *   Menus
 * ========================================================================== */

/* Global Nav Objects */
@mixin menu-flyout{
  position:absolute;
  z-index:99;

  background:$menu_flyout_background;
  color:$menu_flyout_color;
  padding:0;
  width:100%;
  min-width:235px;
  padding-bottom:5px;

  // Link & Border Color
  li{
    border-color:darken($menu_flyout_background, 5%);
  }
  a{
    color:$menu_flyout_color;
    &:hover{
      background-color:$highlight_sec;
    }
  }
}

/* Basics */
.menu{
  margin:0;
  padding:0;
  li{
    list-style:none;
    display:block;
    position:relative;
    &:last-child{
      border-bottom:0 none;
    }
    a{
      display:block;
      text-decoration:none;
      padding:9px $grid;
      outline:0;
      line-height:normal;
    }
  }
  @include clearfix-after;
}

/* Horizontal */
@mixin menu--horizontal{
  // Attiontion: Defining the breakpoint has to be located in the block styling (caus we cant determine here, how long the menu is and in wich content it exists)
  > li{
    float:left;
    border-bottom:0 none;
  }
}


/* Vertical (for overriding) */
@mixin menu--vertical{
  > li{
    float:none;
    border-right:0 none;
    border-bottom:1px solid $grey_light;
  }
}

.menu--horizontal{
  .menu{
    @media only screen and (min-width: $tablet_portrait_end) {
      @include menu--horizontal();
    }
  }
}

/* Dropdown */
%menu--hover{
  // We need this to have the ability, to remove the hover class, to get a proper touch menu
  .expanded{
    > .menu{
      display:none;
    }
    &:hover{
      > a{
        background-color:$menu_flyout_background !important;
        color:$menu_flyout_color !important;
      }
      > .menu{
        display:block;
      }
    }
  }
}

$dropdownDepth_1_color: darken($menu_flyout_background, 8%);
$dropdownDepth_2_color: darken($menu_flyout_background, 12%);
$dropdownDepth_3_color: darken($menu_flyout_background, 15%);
%menu--dropdown{
  .menu{
    display:none;
    top:100%;
    left:0;
    @include menu-flyout;
    .btn-expandable-menu-item--closer{
      background-color:$dropdownDepth_1_color;
    }
    .menu{
      // Depth 1
      background-color:$dropdownDepth_1_color;
      .btn-expandable-menu-item--closer{
        background-color:$dropdownDepth_2_color;
      }
      .menu{
        // Depth 2
        background-color:$dropdownDepth_2_color;
        .btn-expandable-menu-item--closer{
           background-color:$dropdownDepth_3_color;
        }
        .menu{
          // Depth 3
          background-color:$dropdownDepth_3_color;
        }
      }
    }
  }
  .expanded{
    &.open{
      > .menu{
        display:block;
      }
    }
  }
  .expanded:not(.expandable-menu-item){
    &.open{
      > a{
        background-color:$menu_flyout_background;
        color:$menu_flyout_color;
      }
    }
  }
}
%menu--dropdown-horizontal{
  // Secondary Menu Flyouts opens right, instead of bottom
  .menu{
    left:100%;
    top:0;
    bottom:auto;
    right:auto;
  }
}

/* Expandable Menu Items (touch support) */
.expandable-menu-item{
  > .btn-expandable-menu-item{
    width:40px;
    height:100%;
    max-height:44px;
    display:block;
    text-align:center;
    position:absolute;
    right:0;
    top:0;
    border-left:1px solid $grey;
    cursor:pointer;
    text-indent:-9000px;
    &.btn-expandable-menu-item--opener{
      @include get-ico('arrow-thin-bottom');
    }
    &.btn-expandable-menu-item--closer{
      @include get-ico('arrow-thin-top');
    }
    &::before{
      width:40px;
      height:40px;
      font-family:$iconfont;
      text-indent:0;
      float:left; // show despite of text-indent
      border-radius:0;
      line-height:40px;
    }
  }
  > a{
    margin-right:40px; // == width .btn-opener
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
}


/* Block / Pane / Custom Classes */
// Classes to set to a menu wrapper

/* Dropdown */
.webks-menu-hover{
  @extend %menu--hover;
}
.webks-menu-dropdown{
  .menu{
    @extend %menu--dropdown;
    .depth-0.last{
      > .menu{
        left:auto;
        right:$grid;
      }
    }
    .menu{
      @extend %menu--dropdown-horizontal;
    }
  }
}
.webks-menu-dropdown--horizontal{
  .menu{
    @extend %menu--dropdown-horizontal;
  }
}

/* Menu Specific */
/* -- Main Menu */
.region-navigation-bar,
.drowl-headroom-wrapper{
  .menu-block-wrapper > .menu{
    @media #{$large-up}{ // Breakpoint when the menu gets horizontal in this region
      @include menu--horizontal;
    }
    // Main Menu Styles goes here
    margin-right:-$grid;
    > li{
      width:16.666%;
      padding-right:$grid;
      > a{
        font-size:$font_large;
        line-height:1.1em;
        padding-left:0;
        border-bottom:1px solid $grey;
        transition-property: all;
        &.active,
        &.active-trail{
          color:darken($grey_dark, 30%);
          border-color:$highlight_sec;
        }
      }
      &:hover > a{
        padding-left:$grid;
        border-color:$highlight;
      }
      &.expanded > a:hover{
        background-color:$highlight_sec !important;
        border-color:$highlight_sec;
      }
    }
  }
}
/* -- Mobile Nav Bar */
.drowl-headroom-wrapper{
  background:white;
  > .region-inner{
    @include clearfix-after;
  }
  // Main Menu
  @if $headroom_searchblock == true{
    .block-search{
      float:right;
      width:25%;
      margin-top:4px;
    }
    .block-menu-block{
      float:left;
      width:75%;
    }
  }
  &.shrinked-main-menu-wrapper{
    .block-search{
      float:left;
      width:75%;
      padding-right:$thin_grid;
      box-sizing:border-box;
    }
    .block-menu-block{
      float:right;
      width:25%;
    }
  }
  .menu-block-wrapper > .menu{
    @media #{$large-up}{ // Breakpoint when the menu gets horizontal in this region
      @include menu--horizontal;
    }
    // Main Menu Styles goes here
  }
}

/* =============================================================================
 *   Headroom.js | Mobile Helpers Bar
 * ========================================================================== */
.headroom {
  position:relative;
  z-index:999;
  transition: transform 200ms linear;

  transform: none !important;
}

.headroom--not-top{
  width:100% !important; // Prevent from getting overwritten
  padding:0 !important; // Prevent from getting overwritten
  position:fixed;
  top:0;
  left:0;
  z-index:9999;
  > .region-inner{
    max-width:$page_max_width;
    margin:0 auto;
    padding:5px;
  }
  .block{
    margin:0;
  }
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
  margin-top:-1px; // compensate border
}
// Hide headroom bar if the menu flyout is open
.drowl-headroom-wrapper--invisible{
  // override module default behavoir
  display:block;
  position:fixed;
  top:0;
  left:0;
  top:-100%;
}

.touchmenu-open .drowl-headroom-wrapper{
  // @include transform(translateY(-100%)); // this wont work because translate touches fixed elements also
  top:-100%;
}

/* Shrinked Main Menu (Off Canvas)*/
$offCanvasItemPaddingY: 10px;
$offCanvasItemPaddingX: $mobile_grid;
$offCanvasItemFontSize:$font_size_px;
$offCanvasItemLineHeight:$font_size_px + 4px;
$offCanvasExpItemTriggerWidth:($offCanvasItemPaddingY * 2) + $offCanvasItemLineHeight;

.shrinked-main-menu-wrapper{
  .menu-block-wrapper > .menu > li > .menu{
    top:0;
  }
}
.shrinked-main-menu-origin{
  // hide default menu
  display:none;
}

.expandable-menu .shrinked-main-menu-triggers{
  list-style:none;
  li{
    display:block;
    border:0 none;
  }
  a{
    @include btn('default', 'default', 'menu');
    display:block;
  }
}

/* Off Canvas Menu */
.webks-offcanvas-layer{
  display:none;
  position:fixed;
  right:0;
  top:0;
  z-index:10000;
  height:100%;
  max-height:none !important; // Override Max-height Script (needed for normal, none offcanvas dropdowns)
  width:90%;
  background-color:$menu_offcanvas_background;
  &.open{
    display:block;
  }
  .btn-expandable-menu-item--closer{
    background-color:$dropdownDepth_1_color;
  }
  .menu{
    position:static !important;
    .menu{
      // Depth 1
      background-color:$dropdownDepth_1_color;
      .btn-expandable-menu-item--closer{
        background-color:$dropdownDepth_2_color;
      }
      .menu{
        // Depth 2
        background-color:$dropdownDepth_2_color;
        .btn-expandable-menu-item--closer{
           background-color:$dropdownDepth_3_color;
        }
        .menu{
          // Depth 3
          background-color:$dropdownDepth_3_color;
        }
      }
    }
  }
  li{
    border-bottom:1px solid darken($menu_offcanvas_background, 5%);
    font-size:$offCanvasItemFontSize;
    line-height:$offCanvasItemLineHeight;
    a{
      color:$menu_offcanvas_color;
      padding:$offCanvasItemPaddingY $offCanvasItemPaddingX;
    }
    &.expanded{
      &.open{
        > a{
          border-bottom:1px solid darken($menu_offcanvas_background, 5%);
        }
      }
    }
  }
  .expandable-menu-item{
    > a{
      margin-right:$offCanvasExpItemTriggerWidth;
    }
    > .btn-expandable-menu-item{
      width:$offCanvasExpItemTriggerWidth;
      max-height:$offCanvasExpItemTriggerWidth;
      &::before{
        width:$offCanvasExpItemTriggerWidth;
        height:$offCanvasExpItemTriggerWidth;
        line-height:$offCanvasExpItemTriggerWidth;
      }
    }
  }
  .menu-mlid-close{
    display:none;
  }
  // Search Block
  .block-search-form{
    border-bottom:1px solid darken($menu_offcanvas_background, 5%);
    margin-bottom:5px;
    padding:10px 60px 10px $mobile_grid !important; // 60px free space for the close button
    float:none !important;
    width:100% !important;
    .form-submit{
      background-color:darken($menu_offcanvas_background, 5%) !important;
    }
  }
  .block-menu-block{
    // free space for the close button
    padding-top:55px;
  }
  .block-search-form + .block-menu-block{
    // Remove free space if the search block exists
    padding-top:0;
  }
}
button.drowl-offcanvas__close-btn{
  @include ico-replacement('circle-remove', $menu_offcanvas_color, 22px);
  position:absolute;
  right:7px;
  top:10px;
  z-index:3;
  padding-top:7px;
  padding-bottom:7px;
  width:40px;
  background-color:darken($menu_offcanvas_background, 5%) !important;
}

@media only screen and (min-width: $tablet_landscape_start) {
  .shrinked-main-menu-triggers li a{
    text-indent:0;
    &::before{
      display:inline-block;
      position:static;
      margin-right:5px;
      margin-bottom:-5px;
      width:auto;
    }
  }
}

/* =============================================================================
 *   Pager
 * ========================================================================== */
.pager{
  clear: both;
  margin: 0;
  text-align: center;
  list-style-type: none;
  li{
    margin:0 5px 0 0;
    display: inline-block;
  }
  .pager-current{
    @include btn('ghost', 'xs');
  }
  a{
    @include btn('default', 'xs');
  }
}

/* =============================================================================
 *   Tabs
 * ========================================================================== */
/* Primary */
ul.primary,
#login-links,
.ui-tabs .ui-tabs-nav,
.horizontal-tabs-list{
  border-color:$grey_light;
  border-width:0 0 1px 0;
  border-style:solid;
  border-radius:0;
  padding:0;
  list-style:none;
  margin:0.5em 0 1em 0;
  line-height:normal;
  @include clearfix-after;
  li{
    // Undo some jquery ui styles
    border:0 none;
    background:none;
    color:$text_color;
    &.active a,
    &.ui-tabs-active a,
    &.selected a{
      color:$highlight;
      @include vertical-gradient($grey_light, #fff);
    }
    a{
      display:block;
      padding:7px 10px;
      text-decoration:none;
      outline:0;
      border-width:1px 1px 0 1px;
      border-style:solid;
      border-color:$grey;
      border-radius:0;
      background:$grey_light;
      strong{
        font-weight:normal; // "Bugfix" for the fieldgroup tabs, somebody thought its a good idea to capsulate it with STRONG
      }
      &:hover,
      &:focus{
        color:$text_color;
        background:white;
      }
      &:focus{
        outline:0;
        border-color:darken($grey_light, 8%);
      }
    }
  }
}
/* -- Tabs Content Wrapper */
.horizontal-tabs-panes{
  clear:both;
  padding:10px ($grid / 2);
  background:white;
  border:1px solid $grey;
  .field-group-htab{
    padding:0;
  }
  .horizontal-tabs-pane{
    > legend{
      display:none !important;
    }
    > .fieldset-wrapper{
      padding-left:0 !important;
      padding-right:0 !important;
    }
  }
  .horizontal-tab-hidden{
    display:none;
  }
}

@media only screen and (min-width: $tablet_portrait_start) {
  ul.primary,
  #login-links,
  .ui-tabs .ui-tabs-nav,
  .horizontal-tabs-list{
    li{
      float:left;
      margin-right:3px;
    }
    li a.active,
    li a.lt-active,
    li.selected a,
    li.ui-tabs-active > a{
      margin-bottom:-1px;
      padding-top:8px; // compensate negative amount
      color:$highlight;
      background:white;
    }
  }
  .horizontal-tabs-list{
    margin-bottom:-1px;
  }
}

/* Secondary */
ul.secondary{
  margin:0 0 8px 0;
  padding:0.55em 10px;
  border-width: 0 1px 1px 1px;
  border-style:solid;
  line-height:normal;
  border-color:$grey_light;
  @include vertical-gradient(#fff, $grey_light);
  @include clearfix-after;
  li{
    border-color:#eee;
    float:left;
    margin-right:7px;
    padding-right:7px;
    list-style:none;
    border-width:0 1px 0 0;
    border-style:solid;
    &:last-child{ border-width:0; }
    a{
      display:block; text-decoration:none;
    }
  }
}

/* Vertical tabs */
div.vertical-tabs{
  margin:1em 0px 1em 0;
  width:100%;
  border:0 none;
  box-sizing:border-box;
  ul.vertical-tabs-list{
    margin:0;
    padding:0;
    list-style:none;
    border-width:0 3px 0 0;
    border-style:solid;
    border-color:$highlight;
    box-sizing:border-box;
    @media only screen and (min-width: $tablet_landscape_start) {
      width:30%;
      float:left;
    }
    li{
      border:0 none;
      margin-bottom:2px;
      a{
        color:$text_color;
        text-decoration:none;
        display:block;
        padding:10px;
        line-height:1.1em;
        border-width:1px 0 1px 1px;
        border-style:solid;
        border-color:white;
        background-color:white;
        .form-required{
          color:white;
        }
        strong{
          font-weight:normal;
        }
        .summary{
          display:block;
          color:$grey;
        }
        &:hover{
          outline:0;
        }
      }
      &:hover{
        border-color:$grey_light;
        background:white;
      }
      &.selected{
        a{
          background:$highlight;
          border-color:$highlight;
          border-radius:$border_radius 0 0 $border_radius;
          .summary{
            color:lighten($highlight, 35%);
          }
        }
        strong{
          color:white;
        }
      }
    }
  }
  .vertical-tabs-panes{
    clear:both;
    border-width:0 0 3px 0;
    border-style:solid;
    border-color:$highlight;
    margin-left:-3px; // prevent double border (we use this workaround to get a 100% height border)
    box-sizing:border-box;
    @media only screen and (min-width: $tablet_landscape_start) {
      clear:none;
      float:left;
      width:70%;
      border-width:0 0 0 3px;
    }
  }
  .vertical-tabs-pane{
    margin:0;
    border:0 none;
    box-sizing:border-box;
    > legend{
      display:none;
    }
  }
}

/* Action Links */
ul.action-links {
  margin: 20px 0 0;
  list-style: none;
  li {}
}

/* jQuery Tabs */
.ui-tabs{
  border:0 none;
}
